<template>
    <div class="content">
        <div class="menu-box" v-for="(item, index) in menuList" :key="index">
            <img alt src="../../assets/img/common/gap.png" class="gap"/>
            <ul v-for="(menu, i) in item" :key="i">
                <li @click="clickMenu(menu)">
                    <img alt :src="require('@/assets/img/menu/'+menu.img)" />
                    <span>{{ menu.title }}</span>
                </li>
                <span class="mark" v-if="menu.eventKey=='k_alarm_list' && warnNum>0">{{ warnNum }}</span>
                <span class="mark" v-else-if="menu.eventKey=='k_ais' && $store.state.showAisList==true" style="font-size: 8px;">开启中</span>
                <!-- <span class="mark" v-else-if="menu.eventKey=='k_measure_range'" style="font-size: 8px;">测量中</span> -->
            </ul>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            menuList: [
                [
                    {
                        title: '全屏',
                        img: 'full_screen.png',
                        eventKey: 'k_full_screen',
                    }
                ],
                [
                    {
                        title: '放大',
                        img: 'zoom_in.svg',
                        eventKey: 'k_zoom_in'
                    },
                    {
                        title: '缩小',
                        img: 'zoom_out.svg',
                        eventKey: 'k_zoom_out'
                    },
                    {
                        title: '海图配置',
                        img: 'sea_chart.png',
                        eventKey: 'k_sea_chart'
                    },
                ],
                [
                    {
                        title: '船舶列表',
                        img: 'ship_list.svg',
                        eventKey: 'k_ship_list'
                    },
                    {
                        title: '标注列表',
                        img: 'mark.svg',
                        eventKey: 'k_mark_list'
                    },
                    {
                        title: '定位',
                        img: 'location.svg',
                        eventKey: 'k_location',
                    }
                ],
                [
                    {
                        title: '航点管理',
                        img: 'waypoint.svg',
                        eventKey: 'k_waypoint_list'
                    },
                    {
                        title: '航线列表',
                        img: 'waylist.svg',
                        eventKey: 'k_way_list'
                    }
                ],
                [
                    {
                        title: '航班信息',
                        img: 'voyage.svg',
                        eventKey: 'k_voyage_info',
                    },
                    {
                        title: '航程统计',
                        img: 'stat.svg',
                        eventKey: 'k_stat_voyage',
                    },
                ],
                [
                    {
                        title: '测量距离',
                        img: 'm1.svg',
                        eventKey: 'k_measure_range',
                    },
                    {
                        title: '测量区域',
                        img: 'm2.svg',
                        eventKey: 'k_measure_area',
                    },
                    {
                        title: 'EBL/VRM',
                        img: 'm3.svg',
                        eventKey: 'k_measure_ebl',
                    },
                ],
                [
                    {
                        title: '历史轨迹',
                        img: 'track.svg',
                        eventKey: 'k_track'
                    },
                    {
                        title: '查看视频',
                        img: 'camera.svg',
                        eventKey: 'k_camera'
                    }
                ],
                [
                    {
                        title: '告警列表',
                        img: 'alarm.svg',
                        eventKey: 'k_alarm_list'
                    },
                ],
                [
                    {
                        title: 'AIS',
                        img: 'ais.svg',
                        eventKey: 'k_ais',
                    },
                    {
                        title: '气象',
                        img: 'weather.png',
                        eventKey: 'k_weather'
                    },
                ],
                
                // [
                //     {
                //         title: '测试',
                //         img: 'alarm.svg',
                //         eventKey: 'k_test'
                //     },
                // ],
                // [
                //     {
                //         title: '测试2',
                //         img: 'alarm.svg',
                //         eventKey: 'k_test2'
                //     },
                // ]
            ],

            warnNum: 0, 
        }
    },
    methods: {
        clickMenu(menu){
            let data = {
                eventKey: menu.eventKey
            }
            this.$emit('click-menu-event', data)
        },

        /**
         * 更新告警数量
         * @param n 0:表示重置0 其他数据表示自增值
         */
        updateWarnNum(n){
            console.log(n)
            if(n==0){
                this.warnNum = 0
            }else{
                this.warnNum += n
            }
        }
    }

}

</script>

<style lang="scss" scoped>
    .content {
        height: 60px; 
        border: 1px solid #000; 
        border-radius: 4px; 
        background: linear-gradient(to right,#3ebcf6,#1495f5,#47bff6,#015ec8);
        display: flex;

        overflow-x: auto;
        flex-wrap: wrap;
        align-items: center;
        
        .menu-box {
            display: flex;
            height: 58px;
            .gap {
                width: 2px;
                margin-left: 6px;
                margin-right: 6px;
            }
            ul {
                display: flex;
                align-items: center;
                position: relative;
                li {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    color: #000;
                    font-size: 12px;
                    padding: 2px 8px;
                    user-select: none;
                    // border: 1px solid red;
                    white-space: nowrap;
                    img {
                        height: 30px;
                    }
                    cursor: pointer;
                }
                li:hover{
                    background: rgba(241, 243, 245, 0.493);
                    border-radius: 5px;
                }
                .mark {
                    position: absolute;
                    top: 4px;
                    right: 4px;
                    color: #FFF;
                    font-size: 10px;
                    background-color: #ce1a1a;
                    padding: 0 2px;
                    border-radius: 4px;
                }
            }
        }
    }

    
</style>

<style>
    /* 自定义横向滚动条样式 */
    .content::-webkit-scrollbar {
        width: 10px;
    }
    .content::-webkit-scrollbar-thumb {
        background-color: #85e984; /* 设置滚动条颜色 */
        border-radius: 2px; /* 设置滚动条圆角 */
    }
</style>