<template>
    <div class="content" v-if="show">
        <div class="v1" style="right: 365px; width: 280px;">
            <div class="modal-content" @click.stop>
                <div class="box-top">
                    <span class="box-title">{{ boxTitle }}</span>
                    <div class="top-btn">
                        <img alt src="../../assets/img/common/close.png" @click="close()"/>
                    </div>
                </div>
                <!-- <div class="box-content" style="min-height:200px; max-height: 395px;">
                    <ul class="table-title" style="cursor: default;">
                        <li class="c1">序号</li>
                        <li class="c2">航点名称</li>
                        <li class="c3"></li>
                    </ul>
                </div> -->
                <el-form label-width="70px" style="padding: 10px 5px;">
                    <el-form-item label="标注名称">
                        <el-input v-model="markName" clearable maxlength="20" placeholder="请输入名称"></el-input>
                    </el-form-item>
                    <el-form-item label="图标样式">
                        <div class="mark-icon">
                            <div class="mark-item" v-for="(item, i) in iconList" :key="i" :style="{backgroundColor: markStyle==i?'#c5d6e0':''}" @click="chooseIcon(i)">
                                <img alt :src="require('@/assets/img/mark/'+item)"/>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="经度">
                        <el-input v-model="markPoints[0].lot" clearable maxlength="20"></el-input>
                    </el-form-item>
                    <el-form-item label="纬度">
                        <el-input v-model="markPoints[0].lat" clearable maxlength="20"></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <el-checkbox v-model="moveIcon">移动图标修改经纬度</el-checkbox>
                        <li class="el-icon-location-information aim" title="定位到经纬度位置" @click="toMapCenter()"></li>
                    </el-form-item>
                </el-form>
                <div class="box-footer">
                    <button @click="sure">保存</button>
                    <button @click="close()">取消</button>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import {
    SceneItemCommon,
    SceneRichPointItem,
    Pixmap
} from '../../../public/hd/chartbox';

import { llPointToENPoint, enPointToLLPoint } from '@/utils';

export default {
    inject: ['parent'],
    props: {
        iconList: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            show: false,
            dataList: [],
            dg: {
                p: {
                    routeName: '',
                    waypoints: ''
                }
            },
            currWayPointId: 0,

            currOperate: '', // 当前操作 add edit del
            
            // 航线包含的航点列表
            dataList2: [],
            chooseIds: [],
            // 航线名称
            routeName: '',
            // 当前编辑航线id
            currWayId: 0,
            // 当前选择的航点id  编辑的时候用到
            currWayPoints: '',
            boxTitle: '新增标注点',


            currMarkId: 0,          // 当前标注id
            currMarkType: 0,        // 当前标注类型 1:点 2:线 3:面
            markName: '',           // 标注名称
            markStyle: 0,           // 标注样式
            markPoints: [],         // 标注点列表

            moveIcon: false,         // 移动图标修改经纬度


        }
    },

    watch: {
        moveIcon(newVal) {
            console.log(newVal)
            if(newVal){
                if(this.currOperate=='add'){
                    this.parent.instanceProvide.markTempLayer.items()[0].setState(2)
                }else{
                    this.parent.instanceProvide.markLayer.items()[this.parent.currMarkIndex].setState(2)
                }
            }else{
                if(this.currOperate=='add'){
                    this.parent.instanceProvide.markTempLayer.items()[0].setState(0)
                }else{
                    this.parent.instanceProvide.markLayer.items()[this.parent.currMarkIndex].setState(0)
                }
            }
        }
    },

    methods: {

        // 定位到经纬度位置
        toMapCenter(){
            let center = llPointToENPoint(this.markPoints[0].lot, this.markPoints[0].lat)
            this.parent.instanceProvide.map.setCenter(center)
        },

        // 选择新图标
        chooseIcon(i){
            this.markStyle = i
            if(this.currOperate=='edit'){
                this.parent.instanceProvide.markLayer.items()[this.parent.currMarkIndex].setPixmap(new Pixmap({src: require('@/assets/img/mark/'+this.iconList[i])}))
                this.parent.instanceProvide.markLayer.update()
            }else if(this.currOperate=='add'){
                this.parent.instanceProvide.markTempLayer.items()[0].setPixmap(new Pixmap({src: require('@/assets/img/mark/'+this.iconList[i])}))
                this.parent.instanceProvide.markTempLayer.update()
            }
        },

        convertPositionToPoints(position){
            let p = enPointToLLPoint(position[0], position[1])
            let point = {
                lot: parseFloat(p[0].toFixed(7)),
                lat: parseFloat(p[1].toFixed(7))
            }
            let points = []
            points.push(point)
            return points
        },

        // 新增点
        addRichPoint(layer) {
            let markStyle = 7 // 默认索引为7的图标
            const item = new SceneRichPointItem({
                pixmap: new Pixmap({ src: require('@/assets/img/mark/'+this.iconList[markStyle]) }),
                textOffsetY: 20,
                text: "标注点",
                fontSize: 12,
            })
            layer.addItem(item);
            item.on("finish", (obj, state) => {
                console.log(obj.getPosition());
                let p = enPointToLLPoint(obj.getPosition()[0], obj.getPosition()[1])
                let point = {
                    lot: parseFloat(p[0].toFixed(7)),
                    lat: parseFloat(p[1].toFixed(7))
                }
                this.markPoints = [point]
                this.markStyle = markStyle
                // 设置当前操作
                this.setCurrOperate('add', {markType: 1})
            });
            item.on("moveItem", (obj, state) => {
                console.log(obj.getPosition());
                let points = this.convertPositionToPoints(obj.getPosition())
                this.markPoints = points
            });
            item.setState(SceneItemCommon.ItemState.adding);
        },

        // 查询
        queryDataList(){
            this.sswgPost('/waypoint/fetchWaypointList', {agentId: localStorage.getItem('login_agent_id')}).then(res => {
                console.log(res)
                this.dataList = res.data
                
            })
        },

        // 设置当前操作
        setCurrOperate(oper, mark){
            this.currOperate = oper
            if(oper=='add'){
                this.markName = ''
                this.currMarkType = mark.markType
                if(mark.markType==1){
                    this.boxTitle = '新增点'
                    this.setShow()
                }
            }else if(oper=='edit'){
                this.currMarkId = mark.id
                this.markName = mark.label
                this.markPoints = mark.points
                if(mark.markType==1){
                    this.boxTitle = '编辑点'
                    this.markStyle = mark.markStyle
                }
            }else if(oper=='copy'){
                this.boxTitle = '复制添加航线'
            }
        },


        /**
         * 设置编辑时的信息
         * @param wayId         航线id
         * @param routeName     航线名称
         * @param waypoints     包含的航点id
         */
        setEditInfo(wayId, routeName, waypoints){
            this.currWayId = wayId
            this.dg.p.routeName = routeName
            this.currWayPoints = waypoints

            
        },

        // 确认添加或修改
        sure(){

            console.log(this.iconList)

            // return

            if(!this.markName){
                this.$message.warning('请输入标注名称')
            }
            // else if(this.dataList2.length<2){
            //     this.$message.warning('至少选择两个航点')
            // }
            else if(this.currOperate=='add'){
                // this.dg.p.agentId = localStorage.getItem('login_agent_id')
                // // 拼接id
                // let ids = this.dataList2.map(obj => obj.id).join(',')
                // let params = {
                //     agentId: localStorage.getItem('login_agent_id'),
                //     routeName: this.dg.p.routeName,
                //     waypoints: ids
                // }
                // this.sswgPost('/route/addRoute', params, true, 'json').then(res => {
                //     this.$message.success('添加成功')
                //     this.parent.queryDataList()
                //     this.close()
                // })
                let p = {
                    agentId: localStorage.getItem('login_agent_id'),
                    label: this.markName,
                    markType: this.currMarkType,
                    points: this.markPoints,
                    markStyle: 0,
                }
                if(this.currMarkType==1){
                    p.markStyle = this.markStyle
                    this.sswgPost('/mark/saveOrUpdateMark', p, true, 'json').then(res => {
                        this.$message.success('新增成功')
                        this.close(true)
                    })
                }
            }else if(this.currOperate=='edit'){
                let p = {
                    id: this.currMarkId,
                    label: this.markName,
                    markStyle: this.markStyle,
                    points: this.markPoints
                }
                this.sswgPost('/mark/saveOrUpdateMark', p, true, 'json').then(res => {
                    this.$message.success('编辑成功')
                    this.close(true)
                })
            }
        },

        close(isRefresh){
            // 关闭标注编辑状态
            if(this.moveIcon){
                this.moveIcon = false
            }
            // 如果是新增操作，则清除临时标注
            if(this.currOperate=='add'){
                this.parent.instanceProvide.markTempLayer.clearItems()
            }

            this.show = false
            this.dg.p.routeName = ''
            this.currOperate = ''
            this.dataList2 = []
            this.currWayId = 0
            this.currWayPoints = ''

            this.currMarkId = 0

            console.log(isRefresh)
            // 如果标记了刷新或者标注编辑状态
            if(isRefresh || this.moveIcon){
                this.parent.queryDataList()
            }
            
        },

        setShow(){
            if(!this.show){
                // this.queryDataList()
                this.show = true
            }
        }
    }

}

</script>

<style lang="scss" scoped>
    .content {
        .v1 {
            position: absolute;
            z-index: 210;
            right: 650px;
            top: 6px;
            .modal-content {
                z-index: 220;
                background: linear-gradient(to right,#06397b,#015cc3);
                border: 1px solid #b2c8da;
                border-radius: 5px;
                min-width: 200px;   // 根据当前modal变动
                color: #ffffff;
                .box-top {
                    height: 32px;
                    text-align: center;
                    position: relative;
                    border-bottom: 1px solid #b2c8da;
                    .box-title {
                        line-height: 30px;
                        font-size: 14px;
                        user-select: none;
                    }
                    .top-btn {
                        position: absolute;
                        right: 5px;
                        top: 5px;
                        user-select: none;
                        img {
                            cursor: pointer;
                        }
                    }
                }
                .box-content {
                    margin: 5px;
                    background-color: #FFFFFF;
                    max-height: 500px;
                    overflow-y: auto;
                    ul {
                        display: flex;
                        text-align: center;
                        line-height: 22px;
                        font-size: 12px;
                        color: #000;
                        border-bottom: 1px dashed rgb(180, 179, 179);
                        .c1 {
                            width: 40px;
                        }
                        .c2 {
                            width: 120px;
                        }
                        .c3 {
                            width: 90px;
                            i {
                                font-weight: bold;
                                font-size: 14px;
                                width: 30px;
                                color: #2f752e;
                                cursor: pointer;
                            }
                        }
                        .w60 {
                            width: 60px;
                        }

                    }
                    ul:hover {
                        background-color: #86e9f9;
                    }
                    .table-title {
                        color: #FFF;
                        font-size: 12px;
                        line-height: 20px;
                        user-select: none;
                        background-color: #0393ed;
                    }
                    .table-title:hover{
                        background-color: #0393ed;
                    }
                }


                .mark-icon {
                    border: 1px solid #FFF;
                    border-radius: 4px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .mark-item {
                        width: 35px;
                        height: 35px;
                        // border: 1px solid red;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        
                    }
                    :hover {
                        cursor: pointer;
                        background-color: #c5d6e0;
                    }
                }

                // 定位图标
                .aim {
                    margin-left: 10px;
                    font-size: 24px;
                    cursor: pointer;
                    color: #3ebcf6;
                }

            }
        }

        .box-footer {
            display: flex;
            justify-content: space-around;
            padding: 10px 0;
            button {
                width: 22%;
                height: 24px;
                line-height: 24px;
                border: 1px solid #000;
                border-radius: 5px;
                background: linear-gradient(to bottom, #8ec8f1, #b9dffb);
                cursor: pointer;
            }
        }
    }
</style>

<style scoped>
    .modal-content >>> .el-form-item {
        margin-bottom: 0px;
    }
    .modal-content >>> .el-form-item__label {
        color: #FFFFFF;
    }
    .modal-content >>> input.el-input__inner {
        font-size: 15px;
        font-weight: bold;
        height: 26px;
        line-height: 26px;
        background: none;
        color: #FFFFFF;
    }

    .modal-content >>> .el-checkbox__label {
        color: #ffffff;
    }
    
    /* 自定义横向滚动条样式 */
    /* .box-content::-webkit-scrollbar {
        width: 10px;
    }
    .box-content::-webkit-scrollbar-thumb {
        background-color: #85e984;
        border-radius: 4px;
    } */
</style>

