<template>
    <div class="content" v-if="showMarkList">
        <div class="v1">
            <span class="title">标注列表</span>
            <div class="p1">
                <img alt src="../../assets/img/common/close.png" @click="close"/>
            </div>
        </div>
        <div class="menu">
            <span @click="addMarkPoint(1)">新增点</span>
            <span @click="addMarkPoint(2)">新增线</span>
            <span @click="addMarkPoint(3)">新增面</span>
            <!-- <span @click="copyWay">复制航线</span> -->
            <span @click="editMark">编辑</span>
            <span @click="delMark">删除</span>
            <!-- <span @click="settingWay">设置</span> -->
            <!-- <span @click="test5">test5</span> -->
        </div>
        <div class="v2">
            <ul class="table-title" style="cursor: default">
                <li class="c1">序号</li>
                <li class="c2">标注名称</li>
                <li class="c3">类型</li>
                <li class="c4">定位</li>
                <li class="c4">显示</li>
            </ul>
            <ul v-for="(item, i) in dataList" :key="i" @click="checkedMark(item, i)" :style="{backgroundColor: currMarkId==item.id?'#3ebcf6':''}">
                <li class="c1">{{ i+1 }}</li>
                <li class="c2">{{ item.label }}</li>
                <li class="c3">{{ convertMarkType(item.markType) }}</li>
                <li class="c4" @click="toLocationCenter(i, item)" @click.stop title="定位到海图中心点">
                    <img alt :src="require('@/assets/img/common/location.svg')" width="22"/>
                </li>
                <li class="c4" @click="clickChecked(i, item)" @click.stop :title="item.showStatus==0?'隐藏':'显示'">
                    <img alt :src="require('@/assets/img/common/'+(item.showStatus==0?'open.svg':'hide.svg'))" width="24"/>
                </li>
            </ul>
        </div>

        <SswgModal :visible.sync="modalVisible" :modalTitle="modalTitle" :openFooter="openFooter" @sure="sureModal">
            <div class="box-content">
                {{ modalContent }}
            </div>
        </SswgModal>

        <MarkDetail ref="markDetailRef" :iconList="iconList"></MarkDetail>
    </div>
</template>

<script>
import MarkDetail from './MarkDetail.vue';

import {
    SceneOnewayFairwayItem,
    SceneItemCommon,
    ScenePointItem,
    SceneRichPointItem,
    SceneLineItem,
    ScenePolygonItem,
    Pen,
    Pixmap
} from '../../../public/hd/chartbox';

import { llPointToENPoint, llPointsToENPoints, enPointToLLPoint } from '@/utils';

export default {
    components: {
        MarkDetail
    },
    // 父级提供的
    inject: ['instanceProvide', 'app'],
    // 本组件提供给子组件
    provide() {
        return {
            parent: this,
        }
    },
    data() {
        return {
            show: false,
            dataList: [],
            
            currWayId: 0,       // 当前航线id
            currWayName: '',
            currWayPoints: '',
            currWayIndex: 0,    // 当前航线索引 删除时使用

            modalVisible: false,
            modalTitle: '提示',
            openFooter: true,
            modalContent: '',

            // 是否首次加载
            firstLoading: true,

            // 标注点的样式
            iconList: [
                'p1.png','p2.png','p3.png','p4.png','p5.png',
                'p6.png','p7.png','p8.png','p9.png','p10.png',
                'p11.png','p12.png','p13.png','p14.png','p15.png',
                'p16.png','p17.png','p18.png','p19.png','p20.png'
            ],
            currMarkId: 0,      // 当前标注id
            currMarkName: '',   // 标注名称
            currMarkIndex: 0,   // 当前标注索引
            currMark: {}        // 当前标注

        }
    },
    computed: {
        hasLogin(){
            return this.$store.state.hasLogin
        },
        showMarkList(){
            return this.$store.state.showMarkList
        }
    },
    mounted(){
        console.log('mounted>>>> WayList.vue')
        // F5刷新场景
        if(this.hasLogin){
            this.queryDataList()
        }
    },
    methods: {

        test5(){
            // console.log(this.instanceProvide.markLayer.items())
        },

        convertMarkType(markType){
            let str = ''
            if(markType==1){
                str = '点'
            }else if(markType==2){
                str = '线'
            }else if(markType==3){
                str = '面'
            }
            return str
        },

        // 查询数据列表
        queryDataList(){
            this.currMarkId = 0
            this.sswgPost('/mark/fetchMarkList', {agentId: localStorage.getItem('login_agent_id')}).then(res => {
                if(res){
                    console.log(res)
                    this.dataList = res.data
                    // 画标注(首次加载，延时5秒)
                    if(this.firstLoading){
                        setTimeout(() => {
                            this.drawMark()
                        }, 5000)
                        this.firstLoading = false
                    }else{
                        this.drawMark()
                    }
                }
            })
        },

        // 画标注
        drawMark(){
            // 先清除
            this.instanceProvide.markLayer.clearItems()
            // for(let i=0; i<this.dataList.length; i++){
            //     this.addWayItemForLayer(this.dataList[i].waypointList, this.dataList[i].showStatus, this.dataList[i].routeName)
            // }
            // // 添加完多条航线之后，更新map
            // this.instanceProvide.map.update()
            for(let i=0; i<this.dataList.length; i++){
                
                this.addMarkItemForLayer(this.dataList[i], this.dataList[i].markType, this.dataList[i].showStatus)
            }
            this.instanceProvide.markLayer.update()
            // this.instanceProvide.map.update()
        },

        convertPointsToEnPoint(points){
            let arr = []
            for(let i=0; i<points.length; i++){
                arr.push([points[i].lot, points[i].lat])
            }
            return arr
        },

        addMarkItemForLayer(mark, markType, showStatus){
            console.log(mark)
            let b = showStatus==0?true:false
            if(markType==1){
                // const item = new ScenePointItem({
                //     text: mark.label,
                //     position: llPointToENPoint(mark.points[0].lot, mark.points[0].lat),
                //     pointSize: 15,
                //     textOffsetY: 15,
                //     horAlign: 2,
                //     fontSize: 11
                //     // fillColor: '#ffff00',
                //     // strokeWidth: 4,
                //     // endType: 1 //结束模式 0:右键结束 1:长按结束(移动端起效)，默认为右键结束，可不用设置此字段
                // })

                // const item = new ScenePixmapItem({
                //     text: "图标",
                //     pixmap: new Pixmap({ src: "/hd/images/point.png" })
                // })
                const item = new SceneRichPointItem({
                    position: llPointToENPoint(mark.points[0].lot, mark.points[0].lat),
                    icon: new Pixmap({ src: require('@/assets/img/mark/'+this.iconList[mark.markStyle]) }),
                    textOffsetY: 18,
                    text: mark.label,
                    fontSize: 11,
                    horAlign: 2,
                    minScale: 9
                })

                item.setVisible(b)
                item.setState(SceneItemCommon.ItemState.normal)
                this.instanceProvide.markLayer.addItem(item)
                item.on("moveItem", (obj, state) => {
                    console.log(obj.getPosition());
                    let p = enPointToLLPoint(obj.getPosition()[0], obj.getPosition()[1])
                    let point = {
                        lot: parseFloat(p[0].toFixed(7)),
                        lat: parseFloat(p[1].toFixed(7))
                    }
                    console.log(point)
                    // this.$refs.markDetailRef.markName = point.lot
                    let points = [point]
                    this.$refs.markDetailRef.markPoints = points
                });
            }else if(markType==2){
                const item = new SceneLineItem({
                    text: mark.label,                 //文本
                    path: llPointsToENPoints(this.convertPointsToEnPoint(mark.points)),
                    fontSize: 12,               //设置字体大小
                    indexVisible: false,         //显示序号
                    strokeColor: '#0037E4',     //线的颜色
                    strokeType: 2,              //线的类型
                    strokeWeight: 3,            //线的宽度
                    minScale: 9
                })
                item.setTextMinScale(9)
                item.setPen("line", new Pen({ color: [0, 0, 255, 1], width: 4 }))
                item.setVisible(b)
                item.setState(SceneItemCommon.ItemState.normal)
                this.instanceProvide.markLayer.addItem(item)
            }else if(markType==3){
                const item = new ScenePolygonItem({
                    text: mark.label,
                    path: llPointsToENPoints(this.convertPointsToEnPoint(mark.points)),
                    fontSize: 12,
                    fillColor: '#ff0000',
                    strokeColor: '#ffff00',
                    indexVisible: false,         //显示序号
                    minScale: 9
                    // text: "面",
                    //  endType:1 //结束模式 0:右键结束 1:左键结束
                })
                // item.setStyleState("text", false);
                item.setVisible(b)
                item.setState(SceneItemCommon.ItemState.normal)
                this.instanceProvide.markLayer.addItem(item)
            }
        },

        /**
         * 添加单条航线
         * @param wayPointList  一条航线的航点
         * @param showStatus    显示状态
         * @param routeName     航线名称
         */
        addWayItemForLayer(wayPointList, showStatus, routeName){
            let b = showStatus==0?true:false
            // 单向航道
            const item = new SceneOnewayFairwayItem({
                text: routeName,
                fillColor: '#f8ff00',
            })
            for(let i=0; i<wayPointList.length; i++){
                const pos = llPointToENPoint(wayPointList[i].lot, wayPointList[i].lat)
                item.addPoint(pos, 80, 80)
            }
            item.setVisible(b)
            item.setState(SceneItemCommon.ItemState.normal)
            this.instanceProvide.wayLayer.addItem(item)
        },

        // 选择标注
        checkedMark(item, i){
            console.log(item)
            // this.currWayId = item.id
            // this.currWayName = item.routeName
            // this.currWayPoints = item.waypoints
            // this.currWayIndex = i

            this.currMarkId = item.id
            this.currMarkIndex = i
            this.currMark = item
            this.currMarkName = item.label

        },

        /**
         * 显示、隐藏
         * @param i     航线索引
         * @param item  
         */
        clickChecked(i, item){
            // 新状态 相反
            let status = item.showStatus==0?1:0
            // 控制显示与隐藏
            let visible = item.showStatus==0?false:true
            this.instanceProvide.markLayer.items()[i].setVisible(visible)
            // 修改图标
            item.showStatus = status
            // 状态保存到数据库
            this.sswgPost('/mark/saveOrUpdateMark', {id: item.id, showStatus: status}, true, 'json').then(res => {})
        },

        toLocationCenter(i, item){
            console.log(item)
            let center = llPointToENPoint(item.points[0].lot, item.points[0].lat)
            this.instanceProvide.map.setCenter(center)
        },

        // 设置标注列表模块显示、隐藏
        /**
        setShow(show){
            if(show==undefined){
                let b = this.show
                if(!b){
                    this.$store.commit('updateShowShipList', 0) // 关闭船舶列表
                    // this.$store.commit('updateShowShipList', 0) // 关闭船舶列表
                    
                    
                    this.queryDataList()
                }
                this.show = !b
            }else{
                this.show = show
            }
        }, */

        close(){
            this.$store.commit('updateShowBySrc', {src: 'mark', val: 0})
        },

        resetData(){
            this.currMarkId = 0
        },

        /**
         * 新增标注
         * @param markType 1:点 2:线 3:面
         */
        addMarkPoint(markType){
            this.resetData()
            this.instanceProvide.markTempLayer.clearItems()
            let message = ''
            if(markType==1){
                message = '1. 鼠标左键点击海图开始标注。 <br/>2. 可移动图标到合适位置。 <br/>3. 鼠标右键结束标注。 <br/>4. 保存。'
                this.showTips('新增标注点', message)
                this.$refs.markDetailRef.addRichPoint(this.instanceProvide.markTempLayer)
            }else{
                this.$notify({
                    title: '功能待完善...',
                    position: 'top-left',
                    duration: 5000,
                    type: 'warning',
                    offset: 100
                });
            }

        },

        showTips(title, message){
            this.$notify({
                title: title,
                dangerouslyUseHTMLString: true,
                message: message,
                position: 'top-left',
                duration: 50000,
                type: 'success',
                offset: 100
            });
        },



        // 添加航线
        // addWay(){
        //     this.currWayId = 0
        //     this.$refs.wayDetailRef.setShow()
        //     this.$refs.wayDetailRef.setCurrOperate('add')
        // },

        // 复制航线
        // copyWay(){
        //     this.$refs.wayDetailRef.close()
        //     if(this.currWayId==0){
        //         this.$message.warning('请先选中复制的航线')
        //         return
        //     }
        //     this.editWay()
        //     // 修改为copy
        //     this.$refs.wayDetailRef.setCurrOperate('copy')
        // },

        //编辑航线
        editWay(){
            this.$refs.wayDetailRef.close()
            if(this.currWayId==0){
                this.$message.warning('请先选中编辑的航线')
                return
            }
            this.$refs.wayDetailRef.setCurrOperate('edit')
            this.$refs.wayDetailRef.setEditInfo(this.currWayId, this.currWayName, this.currWayPoints)
            this.$refs.wayDetailRef.setShow() // 必须放在最后
        },

        // 编辑标注
        editMark(){
            this.$refs.markDetailRef.close()
            if(this.currMarkId==0){
                this.$message.warning('请先选中编辑的标注')
                return
            }

            if(this.currMark.markType!=1){
                this.$notify({
                    title: '线、面编辑功能待完善...',
                    position: 'top-left',
                    duration: 5000,
                    type: 'warning',
                    offset: 100
                });

                return
            }

            this.$refs.markDetailRef.setCurrOperate('edit', this.currMark)
            this.$refs.markDetailRef.setShow() // 必须放在最后
        },

        // 删除航线
        // delWay(){
        //     this.$refs.wayDetailRef.close()
        //     if(this.currWayId==0){
        //         this.$message.warning('请先选中删除的航线')
        //         return
        //     }
        //     this.modalVisible = true
        //     this.modalContent = '确认删除航线【'+this.currWayName+'】吗？'
        // },

        // 删除标注
        delMark(){
            if(this.currMarkId==0){
                this.$message.warning('请先选中删除的标注')
                return
            }
            this.modalVisible = true
            this.modalContent = '确认删除标注【'+this.currMarkName+'】吗？'
        },

        // 确认modal
        sureModal(){
            let p = {
                agentId: localStorage.getItem('login_agent_id'),
                id: this.currMarkId
            }
            console.log(p)
            // return 

            this.sswgPost('/mark/deleteMark?id='+this.currMarkId, p, true, 'json').then(res => {
                this.currMarkId = 0
                this.modalVisible = false
                this.queryDataList()
            })
        },

        settingWay(){
            this.$message.warning('待完善')
        }
    }

}

</script>

<style lang="scss" scoped>
    .content {
        width: 360px;
        height: calc(100% - 118px);
        background-color: #1686d8;
        border: 1px solid #000;
        border-radius: 5px;
        .v1 {
            width: 360px;
            height: 30px;
            text-align: center;
            position: relative;
            .title {
                color: #FFF;
                font-weight: bold;
                line-height: 30px;
                font-size: 15px;
            }
            .p1 {
                background-color: #1686d8;
                position: absolute;
                right: 5px;
                top: 5px;
                img {
                    cursor: pointer;
                }
            }
        }

        .menu {
            // display: flex;
            border-top: 1px solid #c2c0c0;
            padding: 10px 4px;

            span {
                padding: 2px 6px;
                border: 1px solid #e2b46d;
                border-radius: 5px;
                color: #FFF;
                margin-right: 10px;
                cursor: pointer;
                user-select: none;
                font-size: 12px;
            }
        }

        .v2 {
            background-color: #e5efff;
            height: calc(100% - 60px);
            overflow-y: auto;
            border-bottom: 1px solid #000000;
            ul {
                display: flex;
                text-align: center;
                line-height: 26px;
                font-size: 14px;
                color: #000;
                .c1 {
                    width: 50px;
                }
                .c2 {
                    width: 166px;
                }
                .c3 {
                    width: 70px;
                }
                .c4 {
                    width: 70px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .c4:hover {
                    cursor: pointer;
                }
            }
            ul:hover {
                background-color: #86e9f9;
            }
            .table-title {
                background: url(../../assets/img/common/table_bg.png) left top / 100% 100% no-repeat #1832a8;
                color: #FFF;
                font-size: 12px;
                line-height: 16px;
                user-select: none;
            }
        }
    }

</style>