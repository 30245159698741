<template>
    <div id="main-container" class="no-context-menu">
        <ViewSetting ref="viewSettingRef"></ViewSetting>
        <SswgNav></SswgNav>
        <SswgMenu ref="sswgMenuRef" @click-menu-event="handleMenuEvent"></SswgMenu>
        <div id="main-content">
            <div id="map">
                <!--轨迹-->
                <ShipTrack :dataList="trackDataList"></ShipTrack>
                <!--告警提示-->
                <SswgWarningTips ref="sswgWarningTipsRef"></SswgWarningTips>
                <!--AIS-->
                <AisList ref="aisListRef"></AisList>
            </div>
            <!--船舶列表-->
            <ShipList ref="shipListRef" @change="handleShipInfoEmit"></ShipList>
            <!--船舶详情-->
            <ShipDetail ref="shipDetailRef" :targetItem="currShipInfo"></ShipDetail>
            
            <!--航线列表-->
            <WayList ref="wayListRef"></WayList>
            <!--航点管理-->
            <WayPoint ref="wayPointRef"></WayPoint>

            <!--标注列表-->
            <MarkList ref="markListRef"></MarkList>

            <!--航行信息-->
            <VoyageInfo ref="voyageInfoRef"></VoyageInfo>
            <!--航班统计-->
            <StatVoyage ref="statVoyageRef"></StatVoyage>

            
            
        </div>
        
        <!--告警-->
        <WarnList></WarnList>
        


        <div class="footer">
            <ul>
                <li><span>当前层级：</span><span>{{ currentZoom }}级</span></li>
                <li><span>游标位置：</span><span>{{ currentPos[0] }} &nbsp; {{ currentPos[1] }}</span></li>
                <!-- <li><span>缩放比例：</span><span>1 : {{ currentScale }}</span></li> -->
                <!-- <li><span>当前中心点：</span><span>{{ currentCenterPoint }}</span></li> -->
            </ul>
            
        </div>
    </div>
</template>

<script>
import request from '@/sswg/request'
import { Message } from 'element-ui';
import SswgNav from './common/SswgNav.vue';
import SswgMenu from './common/SswgMenu.vue';
import ShipList from './ship/ShipList.vue';
import ShipDetail from './ship/ShipDetail.vue';
import ShipTrack from './ship/ShipTrack.vue';
import WarnList from './warn/WarnList.vue';
import WayList from './way/WayList.vue';
import WayPoint from './way/WayPoint.vue';
import MarkList from './mark/MarkList.vue'; 
import {
    InitSDKEnvironment, Map, GSHHSLayer, S57SliceLayer, TileLayer,
    TargetLayer,
    SceneItemLayer,
    SceneItemCommon,
    TrackLayer, TrackReplayLayer,
    Pen, Brush,
    QuickMeasure, SurveyArea, EBLVRM,
    LatLonGrid
} from '../../public/hd/chartbox'

// 经纬度计算工具
import { lonToDegreeMinString, latToDegreeMinString, llPointToENPoint } from '@/utils'

import ViewSetting from './system/viewSetting.vue'

import VoyageInfo from './stat/VoyageInfo.vue'
import StatVoyage from './stat/StatVoyage.vue'
import SswgWarningTips from './common/SswgWarningTips.vue'

import AisList from './ais/AisList.vue'

export default {
    components: {
        SswgWarningTips,
        SswgNav,
        SswgMenu,
        ShipList,
        ShipDetail,
        ShipTrack,
        ViewSetting,
        WarnList,
        WayList,
        WayPoint,
        VoyageInfo,
        StatVoyage,
        AisList,
        MarkList
    },
    provide() {
        return {
            instanceProvide: this.instance, // 向下层组件传递所需图层实例
            app: this,
        }
    },
    data() {
        return {
            // 图层实例
            instance: {
                map: null,
                gshhsLayer: null,
                s57Layer: null,
                itemLayer: null,                // 船舶图层
                targetTrackLayer: null,         // 轨迹图层
                trackReplayLayer: null,         // 轨迹回放图层
                wayLayer: null,                 // 航线图层
                markLayer: null,                // 标注图层
                markTempLayer: null,            // 标注临时图层
                aisLayer: null,                 // AIS船舶图层
            },
            // 电子海图显示参数
            s57LayerParams: {
                language: "ZH",                 //语言
                displayMode: 2,                 //显示模式
                colorName: 'roulan',            //主图颜色
                showText: true,                 //是否显示文字
                showImportantTextOnly: false,   //仅显示重要文字
                nationLanguage: true,           //使用本地语言
                useScaleMin: true,              //使用最小比例尺
                showsafetydepthcontour: true,   //显示安全等深线
                showShallowMark: false,         //显示浅水标记
                showIsolatedObject: false,      //显示孤立危险物标
                showOutLine: false,             //显示海图边框
                showChartName: false,           //显示海图名称
                symbolStyle: 0,                 //符号风格
                boundaryStyle: 0,               //边界风格
                depthColorMode: 1,              //水深颜色模式
                depthUnit: 0,                   //水深单位
                safetyDepth: 5,                 //安全水深
                baseDeep: 0,                    //基准水深
                shallowContour: 5,              //浅水等深线
                safetyContour: 10,              //安全等深线
                deepContour: 20,                //深水等深线
                showLightRing: false,           //显示灯环
            },
            // 目标显示参数
            targetParameters: {
                showCogLine: false,             //是否显示航速
                showCogLineTime: 500,           //航线时长
                showHdg: false,                 //是否显示船首向
                showHdgLen: 100,                //船首向长度
                showBaseStation: true,          //基站
                showClassA: false,               //A类
                showClassB: true,               //B类
                showNavigationAIDS: true        //助航物标
            },
            //目标轨迹线显示参数
            targetTrackParameters: {
                timeDuration: 600,              //时长
                timeInterval: 4,               //时间间隔
                optimized: true,                //是否优化
                optimizedInterval: 10,          //优化像素
                showPoint: true,                //是否显示轨迹点
                showLine: true,                 //是否显示轨迹线
                showText: true,                 //是否显示文字
            },
            targetInfoVisible: false,
            meteorologyLengendVisible: false,
            targetItem: {},
            targetPopupItem: {},
            currentPos: ["140°45.9095'E", "14°59.8949'N"],
            currentZoom: 9,
            currentScale: 1,
            currentCenterPoint: '',
            lastSelectedTarget: null,

            

            currShipInfo: {},                   // 当前船舶信息
            shipList: [],                       // 船舶列表
            targetList: [],
            shipIdData: {},                     // 海图自营船舶索引数据
            trackDataList: [],                  // 历史轨迹列表

            settingInfo: {},                    // 配置信息

            agentConf: {},                      // 代理商显示设置 
            userConf: {}                        // 用户显示设置

        }
    },
    computed: {
        accountRole(){
            return this.$store.state.accountRole
        }
    },
    created() {
        this.verifyTokenLogin()
    },
    mounted() {
        console.log('>>>>>>>>>>初始化')
        // 禁止右键菜单弹框
        document.getElementById('main-container').addEventListener('contextmenu', function(e) {
            e.preventDefault();
        });

        this.initSDK()
    },
    methods: {

        testKele(){
            console.log('我是可乐')
        },

        // 更新轨迹的选中状态
        updateTrackSelected(){
            console.log('更新。。。。。。。。。')
            this.$refs.shipDetailRef.updateTrackSelected()
        },

        // 刷新船舶列表
        refreshShipList(){
            this.$refs.shipListRef.queryShipList()
        },

        // 查询设置信息 在ShipList.vue里面调用
        querySettingInfo(){
            let p = {
                agentId: localStorage.getItem('login_agent_id'),
                username: localStorage.getItem('login_user_name')
            }
            this.sswgPost('/displaySetting/fetchDisplaySettingInfo', p, true, 'json').then(res => {
                console.log(res)
                if(res && res.code==200){
                    this.agentConf = res.data.agentDisplaySettingVO
                    this.userConf = res.data.userDisplaySettingVO
                }
            })
        },

        // 初始化
        initSDK(){
            InitSDKEnvironment(this.initLayer, "/hd/chartbox.wb", "/hd/msyh.ttf")
        },

        // 初始化图层
        initLayer(){
            // 创建海图
            const map = new Map(document.getElementById("map"), {
                center: [12632603.959228987, 2554418.351891785],
                zoom: 9,
                minZoom: 3,
                maxZoom: 18,
                zoomedFactor: 2,
                bRealUpdate: true
            })

            // 世界轮廓
            const gshhsLayer = new GSHHSLayer({
                source: "/hd/gshhs.rc",
                map: map,
                landColor: '#C7BB84',
                seaColor: '#D4EAEE',
                visible: true
            })

            // const LatLonGridLayer = new LatLonGrid({
            //     map: map,
            //     visible: true,
            //     zIndex: 1000
            // })

            // 添加谷歌地图图层
            const googelLayer = new TileLayer({
                source: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                map: map,
                visible: false,
            })

            // 添加天地图图层
            const tiandituLayer = new TileLayer({
                source: "https://t0.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=b36d5b3dcfb35287a54562b98d5c0558",
                map: map,
                visible: false,
            })

            // 添加地形图图层
            const aMapLayer = new TileLayer({
                source: "https://t0.tianditu.gov.cn/DataServer?T=ter_w&X={x}&Y={y}&L={z}&tk=3ee37040ad8de110ba2d0ec6e8791d96",
                map: map,
                visible: false,
            })

            // S57矢量切片
            const s57Layer = new S57SliceLayer({
                // source: 'https://s57vslice.base.haohaidata.com/s57vslice/',
                source: 'https://vms2.map.sswgsz.com/s57vslice',
                map: map,
                params: this.s57LayerParams,
                visible: true,
                // requestParam: {
                //     code: 'CF5DFD53C8391BB48893A27ED16B8AC8',
                // }
            })
            // 设置电子海图显示参数
            s57Layer.setParameter(this.s57LayerParams)
            s57Layer.hiddenObjectClasses([43], false)
            s57Layer.hiddenObjectClasses([1, 2, 27, 306, 112, 121, 72, 73, 74, 96, 116, 106, 13], true)

            // 添加卫星图标注
            const googelMarkerLayer = new TileLayer({
                source: "http://t0.tianditu.gov.cn/DataServer?T=cia_w&x={x}&y={y}&l={z}&tk=683d1adf34bedc63804cf2861ec163c2",
                map: map,
                visible: false,
            });

            // 添加天地图标注
            const tiandituMarkerLayer = new TileLayer({
                source: "http://t0.tianditu.gov.cn/DataServer?T=cva_w&x={x}&y={y}&l={z}&tk=683d1adf34bedc63804cf2861ec163c2",
                map: map,
                visible: false,
            });

            // 添加地形图标注
            const aMapMarkerLayer = new TileLayer({
                source:
                    "http://t0.tianditu.gov.cn/DataServer?T=cta_w&x={x}&y={y}&l={z}&tk=10284acef61f850b1b99fa03eb2907dc",
                map: map,
                visible: false,
            });

            // 轨迹图层
            const targetTrackLayer = new TrackLayer({ map: map })
            targetTrackLayer.setParameter(this.targetTrackParameters)
            // 轨迹样式
            // targetTrackLayer.getStyle().setShowStyle({
            //     //设置画笔样式
            //     pens: {
            //         "point": new Pen({ color: '#F7F8FA' }),
            //         "line": new Pen({ color: '#2F5DFF', width: 2 }),
            //         "text": new Pen({ color: '#ff0000' })
            //     },
            //     //设置填充样式
            //     fills: {
            //         "point": new Brush({ color: '#F7F8FA' }),
            //         "target": new Brush({ color: '#00ff00' }),
            //     }
            // })


            // 轨迹回放图层
            // const trackReplayLayer = new TrackReplayLayer({
            //     map: map,
            //     showText: true,
            //     showPoint: true
            // })

            // 方式二：
            // targetTrackLayer.getStyle().setShowStyle({
            //     //设置画笔样式
            //     pens: {
            //         "point": new Pen({ color: '#F7F8FA' }),
            //         "line": new Pen({ color: '#2F5DFF', width: 2 }),
            //         "text": new Pen({ color: '#ff0000' })
            //     },
            //     //设置填充样式
            //     fills: {
            //         "point": new Brush({ color: '#F7F8FA' }),
            //         "target": new Brush({ color: '#00ff00' }),
            //     }
            // })



            // 显示当前鼠标经纬度
            const viewport = map.getViewport()
            map.on('mousemove', (e) => {
                const l_enPos = viewport.pixelPointToENPoint(e.offsetX, e.offsetY);
                const [x, y] = viewport.getMercatorTransform().enPointToLLPoint(l_enPos[0], l_enPos[1]);
                this.currentPos = [lonToDegreeMinString(x, 4), latToDegreeMinString(y, 4)]
            })

            map.on('touchmove', (e) => {
                console.log(e.touches[0].clientX, e.touches[0].clientY);
                console.log(e.touches[0].pageX, e.touches[0].pageY);
                console.log(e.touches[0].screenX, e.touches[0].screenY);
            })

            map.on("wheel", () => {
                this.currentZoom = Math.round(map.getZoom())

                this.currentScale = map.getScale()
            })

            // 左键点击
            map.on('click', (e) => {
                const l_enPos = viewport.pixelPointToENPoint(e.offsetX, e.offsetY);
                const [x, y] = viewport.getMercatorTransform().enPointToLLPoint(l_enPos[0], l_enPos[1]);
                console.log('经度>>'+x)
                console.log('纬度>>'+y)

                // console.log('经度x==>>'+l_enPos[0])
                // console.log('纬度y==>>'+l_enPos[1])

                this.$refs.wayPointRef.setLonLat(x, y)
            })

            // 目标图层
            /** 
            const targetLayer = new TargetLayer({
                url: "ws://target.haohaidata.com:8878",
                // url: "ws://47.93.174.109:7788",
                map: map,
                visible: true,
            })
            targetLayer.setParameter(this.targetParameters)
            //设置Label标签中显示的信息，按数组顺序取值，如shipName值为空，则取id
            targetLayer.getStyle().setDisplayInfoKeyList(["shipName", "id"])
            // 目标选中
            targetLayer.on('selectItem', (item) => {
                console.log(item);
                // this.targetInfoVisible = true
                // this.targetItem = item
            })
                */

            const aisLayer = new SceneItemLayer({ map: map});

            //物标图层
            const itemLayer = new SceneItemLayer({ map: map });
            const wayLayer = new SceneItemLayer({ map: map });
            const markLayer = new SceneItemLayer({ map: map });
            const markTempLayer = new SceneItemLayer({ map: map });

            // console.log('1111111111111111')
            // itemLayer.clearItems()
            // console.log('2222222222222222')

            this.instance.map = map
            this.instance.gshhsLayer = gshhsLayer
            // this.instance.targetLayer = targetLayer
            this.instance.itemLayer = itemLayer
            this.instance.aisLayer = aisLayer
            this.instance.wayLayer = wayLayer                           // 航线图层
            this.instance.markLayer = markLayer                         // 标注图层
            this.instance.markTempLayer = markTempLayer                 // 标注临时图层
            this.instance.s57Layer = s57Layer                           // 海图实例


            this.instance.googelLayer = googelLayer                     // 谷歌地图图层
            this.instance.tiandituLayer = tiandituLayer                 // 天地图图层
            this.instance.aMapLayer = aMapLayer                         // 地形图图层
            this.instance.googelMarkerLayer = googelMarkerLayer         // 谷歌地图标注
            this.instance.tiandituMarkerLayer = tiandituMarkerLayer     // 天地图标注
            this.instance.aMapMarkerLayer = aMapMarkerLayer             // 地形图标注
            this.instance.targetTrackLayer = targetTrackLayer           // 轨迹图层
            // this.instance.trackReplayLayer = trackReplayLayer           // 轨迹回放图层

        },

        // 海图放大缩小
        zoomInAndOut(v){
            let currZoom = this.instance.map.getZoom()
            let newZoom = currZoom
            if(v=='in'){
                if(currZoom<18){
                    newZoom++
                }else{
                    Message.warning('当前已经是最大层级')
                }
            }else{
                if(currZoom>3){
                    newZoom--
                }else{
                    Message.warning('当前已经是最小层级')
                }
            }
            if(newZoom!=currZoom){
                this.currentZoom = newZoom
                this.instance.map.setZoom(newZoom)
            }
        },

        // 处理菜单事件
        handleMenuEvent(data){
            if(data.eventKey=='k_full_screen'){                 // 全屏
                let de = document.getElementById("map")
                if (de.requestFullscreen) {
                    de.requestFullscreen()
                } else if (de.mozRequestFullScreen) {
                    de.mozRequestFullScreen()
                } else if (de.webkitRequestFullScreen) {
                    de.webkitRequestFullScreen()
                } else if (de.msRequestFullscreen) {
                    de.msRequestFullscreen()
                } else {
                    Message.warning('当前浏览器不支持全屏')
                }
            }else if(data.eventKey=='k_zoom_in'){               // 放大
                this.zoomInAndOut('in')
            }else if(data.eventKey=='k_zoom_out'){              // 缩小
                this.zoomInAndOut('out')
            }else if(data.eventKey=='k_sea_chart'){             // 海图配置
                this.$refs.viewSettingRef.changCollpase()
            }
            
            else if(data.eventKey=='k_ship_list'){             // 船舶列表
                // 关闭标注列表
                this.$store.commit('updateShowBySrc', {src: 'mark', val: 0})
                // 关闭航线列表
                this.$refs.wayListRef.setShow(false)            

                this.$store.commit('updateShowShipList', 9)
                // 如果是打开
                if(this.$store.state.showShipList){
                    this.$refs.wayListRef.setShow(false)
                }
            }else if(data.eventKey=='k_waypoint_list'){         // 航点管理
                this.$refs.wayPointRef.setShow()
            }else if(data.eventKey=='k_way_list'){              // 航线列表
                // 关闭船舶列表
                this.$store.commit('updateShowShipList', 0)
                // 关闭标注列表
                this.$store.commit('updateShowBySrc', {src: 'mark', val: 0})
                this.$refs.wayListRef.setShow()
            }
            
            else if(data.eventKey=='k_track'){                  // 历史轨迹
                if(this.accountRole.viewLocus!=1){
                    this.$notify({ title: '很抱歉，当前账号无权限', position: 'top-left', duration: 8000, type: 'warning', offset: 100 });
                    return
                }
                if(!this.$refs.shipDetailRef.show){
                    this.$notify({
                        title: '请先选择一艘船舶',
                        dangerouslyUseHTMLString: true,
                        message: '方式一：在船舶列表中选择 <br/>方式二：点击海图中的船舶选择',
                        position: 'top-left',
                        duration: 10000,
                        type: 'warning',
                        offset: 100
                    });
                }else{
                    this.$refs.shipDetailRef.clickTrack()
                }
            }else if(data.eventKey=='k_camera'){                  // 查看视频
                if(this.accountRole.viewVideo!=1){
                    this.$notify({ title: '很抱歉，当前账号无权限', position: 'top-left', duration: 8000, type: 'warning', offset: 100 });
                    return 
                }
                if(!this.$refs.shipDetailRef.show){
                    this.$notify({
                        title: '请先选择一艘船舶',
                        dangerouslyUseHTMLString: true,
                        message: '方式一：在船舶列表中选择 <br/>方式二：点击海图中的船舶选择',
                        position: 'top-left',
                        duration: 10000,
                        type: 'warning',
                        offset: 100
                    });
                }else{
                    this.$refs.shipDetailRef.clickCamera()
                }
            }
            else if(data.eventKey=='k_mark_list'){               // 标注列表
                // 关闭船舶列表
                this.$store.commit('updateShowShipList', 0)
                // 关闭航线列表   
                this.$refs.wayListRef.setShow(false)            

                let b = this.$store.state.showMarkList
                this.$store.commit('updateShowBySrc', {src: 'mark', val: !b})
                if(!b){
                    this.$refs.markListRef.queryDataList()
                }
            }
            else if(data.eventKey=='k_alarm_list'){            // 告警
                this.$store.commit('updateShowWarnList', 9)
                // 告警数量清0
                this.$refs.sswgMenuRef.updateWarnNum(0)
            }else if(data.eventKey=='k_voyage_info'){           // 航行信息
                this.$refs.voyageInfoRef.setShow(true)
            }else if(data.eventKey=='k_stat_voyage'){           // 航程统计
                this.$refs.statVoyageRef.setShow(true)
            }

            else if(data.eventKey=='k_measure_range'){          // 测量距离
                // 先判断是否有其他测量
                this.addQuickMeasure()
                this.$notify({
                    title: '测量距离',
                    dangerouslyUseHTMLString: true,
                    message: '1. 鼠标左键开始测量 <br/>2. 鼠标右键结束测量',
                    position: 'top-left',
                    duration: 30000,
                    type: 'success',
                    offset: 100
                });
            }else if(data.eventKey=='k_measure_area'){          // 测量面积
                this.addSurveyArea()
                this.$notify({
                    title: '测量面积',
                    dangerouslyUseHTMLString: true,
                    message: '1. 鼠标左键开始测量 <br/>2. 鼠标右键结束测量',
                    position: 'top-left',
                    duration: 30000,
                    type: 'success',
                    offset: 100
                });
            }else if(data.eventKey=='k_measure_ebl'){           // EBL/VRM
                this.addEBLVRM()
                this.$notify({
                    title: '测量EBL/VRM',
                    dangerouslyUseHTMLString: true,
                    message: '1. 鼠标左键开始测量 <br/>2. 鼠标右键结束测量',
                    position: 'top-left',
                    duration: 30000,
                    type: 'success',
                    offset: 100
                });                            
            }

            else if(data.eventKey=='k_ais'){
                if(this.accountRole.viewAis!=1){
                    this.$notify({ title: '很抱歉，当前账号无权限', position: 'top-left', duration: 8000, type: 'warning', offset: 100 });
                }else{
                    // this.$refs.aisListRef.openAis()
                    let b = this.$store.state.showAisList
                    // if(b){
                    //     this.$store.commit('updateShowBySrc', {src: 'ais', val: 1})
                    // }
                    this.$store.commit('updateShowBySrc', {src: 'ais', val: !b})
                }
            }

            else if(data.eventKey=='k_test2'){
                // console.log(this.instance.itemLayer.items())

                // let center = [12673268.458276652, 2560724.4067253033]
                // this.instance.itemLayer.items()[1].setCenter(center)


                // console.log(this.instance.targetTrackLayer.items())

                // console.log(this.parseTime(new Date(), '{y}-{m}-{d} 00:00:00'))

                // let xx =new Date('2024-10-31 18:58:00').getTime()/1000
                // console.log(xx)

                
                // this.updateShowWarnMsg('我是告警内容', 2)

                // this.updateShipInfoByWebsocketData(22.34)
                // console.log('>>>>test2')
                // let str = this.getQueryString('token')
                // console.log(str)
                // console.log(typeof(str))
                // if(str){
                //     console.log('>>>>有参数')
                // }else{
                //     console.log('>>>>无参数')
                // }

                this.verifyTokenLogin()
            }

            else if(data.eventKey=='k_test'){
                console.log('测试')

            }else{
                this.$notify({
                    title: '功能模块待完善。。',
                    position: 'top-left',
                    duration: 5000,
                    type: 'warning',
                    offset: 100
                });
                
            }
        },
        
        // 验证token登录
        verifyTokenLogin(){
            let str = this.getQueryString('token')
            if(!str){
                return
            }
            request({
                url: '/user/token',
                method: 'get',
                authLogin: false,
                headers: {
                    "Content-Type": 'application/json;charset=utf-8',
                    Authorization: 'SSWGSZ-VMS'+str
                }
            }).catch(error=>{
                console.log('token登录失败')
            }).then(res=>{
                console.log(res)
                if(res){
                    this.$store.commit('logout')
                    setTimeout(() => {
                        // --begin---参考Login.vue--------------------
                        localStorage.setItem('vms_token', res.data.token)
                        localStorage.setItem('login_agent_id', res.data.agentId)
                        localStorage.setItem('login_user_name', res.data.username)
                        // 更新登录
                        this.$store.commit('updateLogin', {loginUserName: res.data.username})
                        // --end--------------------------------------
                    }, 300);
                }
            })
        },

        addQuickMeasure() {
            if (!this.quickMeasureLayer) {
                this.quickMeasureLayer = new QuickMeasure({ map: this.instance.map });
            }
            this.quickMeasureLayer.turnOn();
        },

        addSurveyArea() {
            if (!this.surveyAreaLayer) {
                this.surveyAreaLayer = new SurveyArea({ map: this.instance.map });
            }
            this.surveyAreaLayer.turnOn();
        },

        addEBLVRM() {
            if (!this.eblVrmLayer) {
                this.eblVrmLayer = new EBLVRM({ map: this.instance.map });
            }
            this.eblVrmLayer.turnOn();
        },

        /**
         * 处理点击船舶事件
         * @param data 当前船舶信息
         * @param src  来源 1:点击船舶列表中的船舶 2:点击海图上面的船舶(不需要居中定位)
         */
        handleShipInfoEmit(data, src){
            console.log(data)
            this.currShipInfo = data
            if(src!==2){ // 调用定位居中
                setTimeout(() => {
                    this.$refs.shipDetailRef.clickAim()
                }, 300);
            }
        },

        // 根据websocket的数据更新船舶信息
        updateShipInfoByWebsocketData(lon, lat, cog, sog, reportTime){
            this.$refs.shipDetailRef.updateShipInfoByWebsocketData(lon, lat, cog, sog, reportTime)
        },

        /**
         * 更新显示告警提示
         * @param msg   信息内容
         * @param n     增加数量 传0表示重置0
         */
        updateShowWarnMsg(msg, n){
            this.$refs.sswgWarningTipsRef.setShow(msg)
            this.$refs.sswgMenuRef.updateWarnNum(n)
        }

    }

}
</script>

<style lang="scss" scoped>

.no-context-menu {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

#main-container {
    position: relative;
    width: 100vw;
    height: 100vh;

    background: rgba(216, 238, 250, 0.6);
    background-image: linear-gradient(white 1px, transparent 0),
        linear-gradient(90deg, white 1px, transparent 0),
        linear-gradient(hsla(0, 0%, 100%, .3) 1px, transparent 0),
        linear-gradient(90deg, hsla(0, 0%, 100%, .3) 1px, transparent 0);
    background-size: 100px 100px, 100px 100px, 10px 10px, 10px 10px;

    #main-content {
        position: relative;
        width: 100%;
        height: 100%;
        // height: 400px;
        // background-color: #1686d8;
        display: flex;
        #map {
            // width: calc(100% - 360px);
            width: 100%;
            height: calc(100% - 118px);
            overflow: hidden;

            border: 1px solid #000;
            border-radius: 5px;
        }
    }

    
}

.footer {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 30px;
    background: linear-gradient(to right, #1686d8,#1832a8);
    z-index: 100;
    border: 1px solid #000;
    ul {
        display: flex;
        padding-left: 4px;
        li {
            line-height: 28px;
            padding: 0 20px;
            font-size: 12px;
            color: #ffffff;
            background: url(../assets/img/common/gap.png) left 0 / 2px 100% no-repeat;
        }
    }
}

.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    cursor: pointer;
    display: none;
}

.modal.show {
    display: block;
}

:deep(.overlay-container) {
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    padding: 8px;
    white-space: nowrap;
}
</style>