import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    hasLogin: localStorage.getItem('hasLogin') == null ? false : true,
    loginUserName: localStorage.getItem('login_user_name'),
    showShipList: true,
    showShipDetail: false,
    showWarnList: false,
    showTrackList: false,               // 显示历史轨迹列表
    showAisList: false,                 // AIS
    showMarkList: false,                // 标注列表

    accountRole: {},                    // 账号角色权限

    showModal: false,
    queryItemsStatus: false,
    targetPopupVisible: false,
    targetPopupItem: false,
    meteorologyVisible: false,
    tidalPopupVisible: false,
    currentSearchItem: null,
    weatherPopupItem: null,
    targetItems: [],
    queryItems: [],
    tidalItem: null,
  },
  getters: {
  },
  mutations: {

    // 登录成功
    updateLogin(state, data){
      localStorage.setItem('hasLogin', '1')
      state.hasLogin = true
      state.loginUserName = data.loginUserName
		},

    // 注销
    logout(state){
      localStorage.removeItem('hasLogin')
      localStorage.removeItem('vms_token')
      state.hasLogin = false
      state.showAisList = false
    },

    // 修改显示
    updateShowShipList(state, data){
      if(data==0 || data==1){
        state.showShipList = data
      }else{
        state.showShipList = !state.showShipList
      }
    },

    // 修改显示
    updateShowShipDetail(state, data){
      state.showShipDetail = data
    },

    // 告警显示
    updateShowWarnList(state, data){
      if(data==0 || data==1){
        state.showWarnList = data
      }else{
        state.showWarnList = !state.showWarnList
      }
    },

    // 更新账号权限
    updateAccountRole(state, data){
      state.accountRole = data
    },

    /**
     * 根据来源设置是否显示
     * @param {*} state 
     * @param {*} data src 来源 val 0:隐藏 1:显示
     */
    updateShowBySrc(state, data){
      if(data.src=='track'){
        state.showTrackList = data.val
      }else if(data.src=='ais'){
        state.showAisList = data.val
      }else if(data.src=='mark'){
        state.showMarkList = data.val
      }

    },

    changeQueryItemsStatus(state, payload) {
      state.queryItemsStatus = payload
    },
    changeModelDispaly(state, payload) {
      state.showModal = payload
    },
    changeTargetPopupDispaly(state, payload) {
      state.targetPopupVisible = payload
    },
    changeMeteorologyDispaly(state, payload) {
      state.meteorologyVisible = payload
    },
    changeTidalPopupDispaly(state, payload) {
      state.tidalPopupVisible = payload
    },
    addTargetPopupItem(state, payload) {
      state.targetPopupItem = payload
    },
    addweatherPopupItem(state, payload) {
      state.weatherPopupItem = payload
    },
    addSearchItem(state, payload) {
      state.currentSearchItem = payload
    },
    addTargetItems(state, payload) {
      state.targetItems = payload
    },
    addQueryItems(state, payload) {
      state.queryItems = payload
    },
    addTidalItem(state, payload) {
      state.tidalItem = payload
    },
  },
  actions: {
  },
  modules: {
  }
})
